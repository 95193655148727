<template>
  <section class="container">
    <div>
      <h1>Tilføj virksomhedsadmin</h1>
    </div>
    <div class="row mt-2 mb-2">
      <div class="col-12">
        <hr />
      </div>
    </div>
    <div class="row">
      <b-form class="col-12" @submit.prevent="onSubmit">
        <div class="row mb-5 mt-3">
          <div class="col-auto mb-2">
            <label
              ><b>{{ $t("global.user.cpr") }}</b></label
            >
            <InputText v-model="cpr" :required="true" />
          </div>

          <div class="col-12">
            <BaseActionButton
              class="btn-add"
              v-bind:clicked="clicked"
              v-bind:disabled="clicked"
              v-show="!disabled"
              type="submit"
              id="signupButton"
            >
              {{ $t("global.create") }}
            </BaseActionButton>
          </div>
        </div>
      </b-form>
    </div>
  </section>
</template>

<script>
import InputText from "@/modules/global/atomComponents/InputText.vue";

export default {
  name: "CompanyAdminAdd",
  components: {
    InputText,
  },
  async mounted() {
    this.$parent.$emit("updateLoader");
  },
  data() {
    return {
      clicked: false,
      disabled: false,
      cpr: null,
    };
  },
  methods: {
    async onSubmit() {
      this.clicked = true;
      try {
        console.log("Setting a companyAdmin");
        await this.$store.dispatch("companyVuex/setCompanyAdmin", {
          cpr: this.cpr,
          companyId: this.companyId,
        });
        this.toast("Success", "En virksomhedsadmin nu tilføjet", true);
        this.$router.push({
          name: "CompanyAdminList",
          params: { companyId: this.companyId },
        });
      } catch (err) {
        this.toast("Error", "Noget gik galt" + err, false);
        this.clicked = false;
      }
      this.clicked = false;
    },
  },
  computed: {
    companyId() {
      return this.$route.params.companyId;
    },
  },
};
</script>

<style></style>
